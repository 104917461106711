<template>
  <div
    ref="container"
    v-enterkey
    class="c-btn-save"
    :class="{ disabled }"
    DA-type="asyncClick"
    :DA-label="detail.goods_sn"
    @click.stop.prevent="toggleSaveStatus"
  >
    <template v-if="showAddBoardBtn && [4, 5].includes(boardToastPosType)">
      <div
        :class="['btn-save__board', boardPosClass]"
        @click.stop="addToBoard"
      >
        <i class="suiiconfont sui_icon_add_circle_18px"></i>
        {{ language.SHEIN_KEY_PWA_17403 }}
      </div>
    </template>

    <template v-if="showAddBoardBtn && [4, 5].includes(boardToastPosType)">
      <div
        class="icon-bg"
        @click.stop.prevent="toggleSaveStatus"
      ></div>
    </template>

    <i
      v-if="!value"
      class="suiiconfont c-btn-save_save-icon sui_icon_save_32px"
      :class="{
        'c-btn-save_anim-start': isSaveStartAnim && !isSaveEndAnim,
        'c-btn-save_anim-end': isSaveEndAnim
      }"
    ></i>
    <i
      v-else
      class="suiiconfont c-btn-save_save-icon sui_icon_save_completed_32px"
      :class="{
        'c-btn-save_anim-start': isSaveStartAnim && !isSaveEndAnim,
        'c-btn-save_anim-end': isSaveEndAnim
      }"
    ></i>

    <div
      class="preload-image"
      style="display: none;"
    >
      <template v-for="img in imageSrc">
        <img
          :key="img"
          :src="img"
          style="width: 100%;"
        />
      </template>
    </div>
    <template v-if="showAddBoardBtn && ![4, 5].includes(boardToastPosType)">
      <div
        :class="['btn-save__board', boardPosClass]"
        @click.stop="addToBoard"
      >
        <i class="suiiconfont sui_icon_add_circle_18px"></i>
        {{ language.SHEIN_KEY_PWA_17403 }}
      </div>
    </template>
  </div>
</template>
<script>
/* globals */
// import { mapMutations } from 'vuex'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from './../../../services/eventCenter'
import { toCampaignLogin } from 'activity/common/toLogin'
import { template } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const { language } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
const needUpdateRoute = ['live_details', 'other_options', 'wishlist']
import { abtservice } from 'public/src/services/abt'
import { getGroupList } from 'public/src/pages/goods_detail/utils/wish.js'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: function () {
        return {}
      }
    },
    propData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    value: {
      type: Boolean || Number,
      default: false
    },
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    from: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: function () {
        return {}
      }
    },
    mallCode: {
      type: [String, Number],
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    isActivity: {
      type: Boolean,
      default: false,
    },
    isRomwe: {
      type: Boolean,
      default: false,
    },
    boardToastPosType: {
      type: Number,
      default: 0
    },
    // 销售属性数据
    skuInfo: {
      type: Object,
      default: () => ( {} )
    },
    quickShip: {
      type: [Boolean, Number],
      default: false,
    }
  },
  data () {
    return {
      language,
      showAddBoardBtn: false,
      imageSrc: [],
      timer: null,
      isSaveStartAnim: false,
      isSaveEndAnim: false,
      groupListData: [], // 收藏分组
    }
  },
  computed: {
    boardPosClass() {
      if ([1, 2, 3, 4, 5].includes(this.boardToastPosType)) {
        return `board_${this.boardToastPosType}`
      }
      return 'board_1'
    }
  },
  methods: {
    // ...mapMutations(['updateCollectedData']),
    preloadImg: function () {
      const annualImgs = [
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/images/img_0.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/images/img_1.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/images/img_2.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/images/img_3.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/images/img_4.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/loading-1744986452.json',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-ball-65a984e21e.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-cloud1-b9158f36a0.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-cloud2-d805d80134.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-cloud3-95f5c62e8d.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-book-eadc6c6124.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-key-318074c1cf.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-ladder-b4e091b1cf.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-postcard-96401981d3.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-postcards-cd4e08a32b.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-projection-cb442a5e99.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-tree-ef7af70349.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/annual-f563e872de.mp3',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/button-active-e8edbbd78c.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/button-normal-d4bf12e72d.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/close-9c6e76d8da.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/more-f49ca2489c.json',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/music-2dec3b0f31.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/notselected-42dff4bfee.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/selected-a3292bbd4d.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/share-2df82bdb3d.png',
        gbCommonInfo.PUBLIC_CDN + '/pwa_dist/images/annual/outfit-3e005bb507.png'

      ]
      this.imageSrc = annualImgs
    },
    async toggleSaveStatus ({ type = '' }) {
      if (this.disabled) return
      if (this.isSaveStartAnim) return
      if (!isLogin()) {
        return toCampaignLogin(this.content, this.propData, () => this.toggleSaveStatus({ type: 'loginCallback' }))
      }
      this.isSaveEndAnim = false
      this.isSaveStartAnim = true
      const saveType = this.value ? 'delete' : 'create'
      const result = await this.doFetch({
        type: saveType
      })
      this.isSaveStartAnim = false
      if (!result) {
        this.$toast(this.language.SHEIN_KEY_PWA_20051 || 'error', 1000)
        return
      }

      // 部分页面需要通信 注意：只能用于单页面
      needUpdateRoute.indexOf(this.from) > -1 && _gb_app_.$store.commit('updateWishStatus', {
        goods_id: this.detail.goods_id,
        isAdd: !this.value
      })
      // 收藏页
      const isWishPage = this?.$route?.name === 'UserWishlist' || location.pathname.includes('wishlist')
      // 更新收藏页数据
      isWishPage && appEventCenter.$emit('update-wishlist', { updateWish: true, type })

      Vue.nextTick(() => {
        this.$refs.container.setAttribute('DA-value', result ? 1 : 0)
      })
      if (result) {
        this.isSaveEndAnim = true
        this.$emit('input', !this.value, { isLogin: true })
      }

      // this.addWishAnalysis({type: saveType, res: result})
    },
    doFetch ({ type }) {
      return new Promise((resolve) => {
        const params = {
          goods_id: this.detail.goods_id,
          mallCode: this.mallCode
        }
        const options = {
          url: `/api/user/wishlist/${type}`,
          headers: {
            'x-csrf-token': gbCommonInfo.csrf_token || ''
          }
        }
        if (type == 'create') { // 收藏时
          if (this.skuInfo && Object.keys(this.skuInfo).length) {
            const { sku_code = '', skc_name, sku_sale_attr = [] } = this.skuInfo
            if (sku_code) Object.assign(params, { sku_code })
            if (skc_name) Object.assign(params, { skc_name })
            // 有尺码就传
            const target = sku_sale_attr.find(item => item.attr_id == '87')
            if (target) Object.assign(params, { attrValueId: target.attr_value_id })  
          }
          Object.assign(options, { method: 'POST', data: params })
        } else { // 取消收藏时
          Object.assign(options, { method: 'GET', params })
        }
        // 请求
        schttp(options).then(data => {
          this.$refs.container.setAttribute('DA-value', 0)
          let activity_from = ''
          this.from == 'getTheLook' && (activity_from = 'get_the_look')
          this.from == 'theOptions' && (activity_from = 'other_options')
          // 【埋点统一】收藏埋点的统一
          daEventCenter.triggerNotice({
            daId: '1-8-1-2',
            target: this.config.sourceTarget || null,
            extraData: {
              code: this.config.code || 'other',
              result: data,
              mall_code: this.mallCode,
              passParams: this.config?.sa?.passParams || {},
              postData: {
                quickShip: this.quickShip,
                action: Number(type == 'create'), // 选中发1, 取消发0
                goods_id: this.detail.goods_id,
                sku: this.detail.goods_sn,
                detail: this.detail,
                index: this.index,
                isShowFollowBelt: this.config.isShowFollowBelt,
                recommendType: this.config.syncClickGaEventCategory === '推荐列表' ? this.config.popupFrom.indexOf('auto_rcmd_goods_list') > -1 ? 2 : 1 : 0,
                locateLabels: this.config?.sa?.mobileVerticalView?.locateLabels,
                feed_type: this.config?.sa?.feed_type || '',
                isAttrFold: this.config?.isAttrFold,
              },
              ranking_from: this.config?.sa?.ranking_from || '',
              page_name: this.config.page_name || '',
              from: activity_from || this.config.sa && (this.config.sa.activity_from || this.config.sa.from) || this.from,
              daEventId: this.config.syncClickGaEventCategory && this.config.syncClickGaEventCategory === '推荐列表' ? '2-4-1' : null,
              review_location: this.config?.review_location || '' // 评论页的收藏
            }
          })
          // if (type == 'add') {
          //   this.$emit('act-sent-bi')
          // }
          if (data?.code == -1) {
            // SHEIN_LOGIN.show({
            //   show: true,
            //   from: 'wishlist',
            //   ga: `game_${actNameEn}`,
            //   sensors: `game_${actNameEn}`,
            //   cb: () => this.toggleSaveStatus()
            // })
            this.isSaveStartAnim = false
            // location.href = langPath + '/user/login?redirection=' + encodeURIComponent(location.pathname + location.search)
          } else if (data?.data?.code == 400427) { // 超出收藏限制
            this.$toast(template(data.data.info.limit, this.language.SHEIN_KEY_PWA_18556), 3000)
            resolve(false)
          } else {
            resolve(data?.data?.code == 0 && data.data.info.result == 1)
            if (type == 'create') {
              // 检查是否要展示收藏分组
              data?.data?.info?.result == 1 && this.checkAddToGroup()
            } else {
              this.showAddBoardBtn = false
            }
            this.$emit('toggleWishStatus', { wishStatus: type == 'create' })

            // dashboard切换到wishList, 需要更新收藏列表
            appEventCenter.$emit('updateWishlist')
          }
        })
      })
    },
    /**
     * 检车收藏之后是否要展示加入收藏分组
     */
    async checkAddToGroup() {
      const state = { show: false, groupList: [] }
      const next = (newState = {}) => {
        const _state = Object.assign({}, state, newState)
        this.showAddBoardBtn = _state.show
        this.groupListData = _state.groupList

        if (this.showAddBoardBtn) {
          daEventCenter.triggerNotice({
            daId: '1-10-2-2',
            extraData: {
              board_count: this.groupListData.length ? 1 : 0
            }
          })

          setTimeout(() => {
            this.showAddBoardBtn = false
          }, 5000)
        }
      }

      const _doneFn = typeof this.config.hookCheckAddToGroup === 'function' ? () => this.config.hookCheckAddToGroup({ state, next }) : () => next()

      this.showAddBoardBtn = false
      if (!this.config.addToWishGroup) {
        _doneFn()
        return
      }

      try {
        state.groupList = await getGroupList()
        // eslint-disable-next-line @shein-aidc/abt/abt
        const abtInfo = await abtservice.getUserAbtResult({ posKeys: ['AddShowGroup'].join(',') })
        state.show = abtInfo.AddShowGroup?.param === 'ShowGroup' || state.groupList.length
      } catch(e) { /** empty */ }

      _doneFn()
    },
    /**
     * 添加到收藏分组
     */
    addToBoard() {
      this.$emit('onAddToBoard')
      daEventCenter.triggerNotice({
        daId: '1-10-2-3',
        extraData: {
          eventCategory: '列表页',
          board_count: this.groupListData?.length ? 1 : 0
        }
      })

      this.$_WD({
        type: 6,
        params: {
          goodsIds: [this.detail.goods_id],
        }
      })
    },
    addWishAnalysis ({ type, res }) {
      const { goods_id, goods_sn } = this.detail
      daEventCenter.triggerNotice({
        daId: '1-8-1-2',
        target: this.config.sourceTarget || null,
        extraData: {
          code: this.config.code || '',
          // scInfo: this.config.scInfo || '', // 神策
          // daEventId: this.config.itemDAEventClickId, // TODO:废弃，可用上面的code
          result: res,
          postData: {
            quickShip: this.quickShip,
            action: type == 'add' ? 1 : 0, // 选中发1, 取消发0
            goods_id,
            sku: goods_sn,
            // 以下3个是收藏时才需要的参数
            detail: this.detail,
            index: this.index,
            recommendType: this.config.moduleFrom && this.config.moduleFrom.indexOf('recommend') > -1 ? this.config.isCccRecommend ? 2 : 1 : 0
          },
          from: this.config.sa && this.config.sa.activity_from || ''
        }
      })
    }
    // handlerSave (from, sku) {
    //   const params = {
    //     hitType: 'event',
    //     eventCategory: '',
    //     eventAction: 'AddToWishlist',
    //     eventLabel: sku,
    //     eventValue: 1
    //   }
    //   if (this.from == 'getTheLook' || this.from == 'theOptions') {
    //     params.eventCategory = '推荐列表'
    //   } else {
    //     params.eventCategory = '列表页'
    //   }
    //   ga(GB_ANALYSIS_GA_SEND, params)
    // },
    // cancalSave (from, sku) {
    //   const params = {
    //     hitType: 'event',
    //     eventCategory: '',
    //     eventAction: 'ClickCancelWish',
    //     eventLabel: sku,
    //     eventValue: 0
    //   }
    //   if (this.from == 'getTheLook' || this.from == 'theOptions') {
    //     params.eventCategory = '推荐列表'
    //   } else {
    //     params.eventCategory = '列表页'
    //   }
    //   ga(GB_ANALYSIS_GA_SEND, params)
    // }
  }
}
</script>


<style lang="less" scoped>
@keyframes goodsli-like-anim-click {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsli-like-anim-loading {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsli-like-anim-complete {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.c-btn-save {
  &.disabled {
    pointer-events: none;

    .c-btn-save_save-icon {
      color: @sui_color_gray_weak1;
    }
  }
  .sui_icon_save_completed_32px {
    color: @sui_color_brand;
  }
  &_anim-start {
    &::before {
      display: block;
      animation: goodsli-like-anim-click .3s cubic-bezier(.4,0,.2,1) forwards, goodsli-like-anim-loading .8s cubic-bezier(.4,0,.2,1) .3s infinite;
    }
  }
  &_anim-end {
    &::before {
      display: block;
      animation: goodsli-like-anim-complete .2s ease both;
    }
  }
  &_save-icon {
    font-size: 0.53rem;
  }
}
.icon-bg {
  position: absolute;
  left: 0.1067rem;
  width: 0.64rem;
  height: 0.64rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}
.btn-save__board {
  transition: transform 0.5s;
  .left(0);
  bottom: 0;
  height: 0.8533rem;
  line-height: 0.8533rem;
  --board-init-top: 0;
  animation: add-board-keyframes 500ms forwards ease;
  // transform: translate3d(0, 0, 0);
  display: flex;
  align-items: center;
  position: absolute;
  height: 0.8533rem;
  padding-left: 0.2666rem;
  padding-right: 0.32rem;
  background: #000;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.8;
  &.active {
    transform: translateY(-1.2rem);
  }
  &.board_1 {
    --board-top: -0.95rem;
    top: var(--board-top);
    left: auto;
    .right(-0.6rem);
    /*rw:begin*/
    border-radius: 2px;
  }
  &.board_2 {
    --board-top: -1.2rem;
    top: var(--board-top);
    .left(0);
    /*rw:begin*/
    border-radius: 2px;
  }
  &.board_3 {
    --board-top: -1.2rem;
    top: var(--board-top);
    /*rw:begin*/
    border-radius: 2px;
  }
  &.board_4 {
    display: inline-block;
    left: 0;
    right: -4.4rem + (0.1067rem * 3 + 0.64rem);
    padding-right: .2135rem;
    padding-left: 0.1067rem * 4 + 0.64rem;
    .text-overflow();
  }
  &.board_5 {
    display: inline-block;
    right: 0;
    left: -4.4rem + (0.1067rem * 3 + 0.64rem);
    padding-left: .2135rem;
    padding-right: 0.1067rem * 4 + 0.64rem;
    .text-overflow();
  }
  .sui_icon_add_circle_18px {
    margin-right: 0.1067rem;
    vertical-align: middle;
    font-size: 18px;
  }
}


@keyframes add-board-keyframes {
  0% {
    opacity: 0;
    top: var(--board-init-top);
  }
  100% {
    opacity: .8;
    top: var(--board-top);
  }
}
</style>
